import "./Footer.scss"

import React from "react"
import gitBranchIcon from "../../images/Octicons-git-branch.svg"
import errorIcon from "../../images/error-svgrepo-com.svg"
import warningIcon from "../../images/warning-svgrepo-com.svg"

const imageStyles = {
  display: "block",
  height: "16px",
}

const Footer = () => (
  <footer>
    <div className="footer-container">
      <img style={imageStyles} src={gitBranchIcon} alt="git branch icon" />
      <span>master</span>

      <img style={imageStyles} src={errorIcon} alt="error icon" />
      <span>0</span>

      <img style={imageStyles} src={warningIcon} alt="warning icon" />
      <span>0</span>
    </div>

    <span>© Mark Laatikainen 2020</span>
  </footer>
)

export default Footer
