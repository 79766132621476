import "./Drawer.scss"

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import downArrow from "../../images/down-chevron.svg"

const siteLinks = [
  { link: "/", text: "index.json", extension: "{ }" },
  { link: "/blog", text: "blog.js", extension: "JS" },
]

const SiteLink = ({ link, text, extension, active }) => (
  <div className={`site-link${active ? " site-link--active" : ""}`}>
    <span className="file-extension">{extension}</span>

    <Link to={link}>{text}</Link>
  </div>
)

const Drawer = ({ path }) => (
  <div className="drawer">
    <div className="drawer__item">EXPLORER</div>

    <div className="drawer__item drawer__item--first-level">
      <img src={downArrow} alt="arrow-down icon" />

      <span>MARKL.FI</span>
    </div>

    <div className="drawer__item drawer__item--second-level">
      <img src={downArrow} alt="arrow-down icon" />

      <span>src</span>
    </div>

    {siteLinks.map(link => (
      <SiteLink key={link.text} {...link} active={link.link === path} />
    ))}

    <div className="drawer__item drawer__item--second-level">
      <img src={downArrow} alt="arrow-down icon" />

      <span>blogposts</span>
    </div>
  </div>
)

Drawer.propTypes = {
  path: PropTypes.string.isRequired,
}

export default Drawer
