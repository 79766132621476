import "./TopNavigation.scss"

import React from "react"
import reactIcon from "../../images/React-icon.svg"

const links = [
  {
    text: "Linkedin",
    url: "https://www.linkedin.com/in/mark-laatikainen-81413a82/",
  },
  {
    text: "GitHub",
    url: "https://github.com/marklaatikainen",
  },
]

const TopNavigation = () => {
  return (
    <div className="top-navigation">
      {links.map(link => (
        <a
          key={link.text}
          className="external-link"
          target="__self"
          href={link.url}
        >
          <img
            className="external-link__image"
            src={reactIcon}
            alt="React icon"
          />

          {link.text}
        </a>
      ))}
    </div>
  )
}

export default TopNavigation
