import "./Header.scss"

import PropTypes from "prop-types"
import React from "react"

const Header = () => (
  <header
    style={{
      background: `#f0f0f0`,
      display: "flex",
      alignItems: "center",
      padding: "6px 16px",
    }}
  >
    <h1
      dangerouslySetInnerHTML={{
        __html: "<u>M</u>ark &nbsp; <u>L</u>aatikainen",
      }}
    />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
