import "./MainNavigation.scss"

import React from "react"
import Drawer from "../Drawer/Drawer"
import PropTypes from "prop-types"

import copyFileIcon from "../../images/copy-file.svg"
import copyFileIconActive from "../../images/copy-file-active.svg"

const MainNavigation = ({ path, isDrawerOpened, onDrawerToggle }) => {
  return (
    <nav
      className="main-navigation"
      style={{
        width: `${isDrawerOpened ? "346px" : "46px"}`,
      }}
    >
      <div className="sidebar">
        <a onClick={() => onDrawerToggle()}>
          <img
            alt="file copy icon"
            src={isDrawerOpened ? copyFileIconActive : copyFileIcon}
          />
        </a>
      </div>

      {isDrawerOpened && <Drawer path={path} />}
    </nav>
  )
}

MainNavigation.propTypes = {
  path: PropTypes.string.isRequired,
  isDrawerOpened: PropTypes.bool.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
}

export default MainNavigation
