import "./layout.css"
import "./index.scss"

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MainNavigation from "./MainNavigation/MainNavigation"
import TopNavigation from "./TopNavigation/TopNavigation"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"

const Layout = ({ children }) => {
  const [path, setPath] = useState("/")
  const [isDrawerOpened, setIsDrawerOpened] = useState(true)

  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  return (
    <div
      style={{
        margin: `0 auto`,
        display: "flex",
        height: "100%",
        boxSizing: "border-box",
        overflow: "hidden",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Header />

      <div style={{ display: "flex", height: "100%" }}>
        <MainNavigation
          isDrawerOpened={isDrawerOpened}
          onDrawerToggle={() => setIsDrawerOpened(!isDrawerOpened)}
          path={path}
        />

        <main>
          <TopNavigation />

          {children}
        </main>
      </div>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
